import { DestroyRef, Injectable, isDevMode, PLATFORM_ID, inject } from '@angular/core';
import { catchError, concatMap, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, fromEvent, merge, Observable, of, OperatorFunction, share } from 'rxjs';
import { GameServerUpdateMessage, Message, TeamSpeakUpdateMessage, Topic } from '../../model/model';
import { TeamSpeakServer as TeamSpeakServer } from '../../model/teamspeak';
import { isPlatformBrowser } from '@angular/common';
import { SocketService } from './socket.service';
import { TriggerService } from './trigger.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { GameServer } from '../../model/gameserver';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly httpClient = inject(HttpClient);
  private readonly destroyRef = inject(DestroyRef);
  private readonly socketService = inject(SocketService);
  protected readonly triggerService = inject(TriggerService);

  public readonly serverUpdates$!: Observable<GameServer>;
  public readonly teamSpeakUpdates$!: Observable<TeamSpeakServer>;

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      // i.e. page reload causes websocket to close but does not destroy the service/component causing the fallback to be activated
      const beforeUnload$ = fromEvent(window, 'beforeunload').pipe(takeUntilDestroyed(this.destroyRef));

      const tsSocketUpdates = this.socketSource('teamspeak', x => (x as unknown as TeamSpeakUpdateMessage).server);
      const tsHttpUpdates = this.triggerService.activeScreenTimer$.pipe(this.fetchHttp<TeamSpeakServer>('teamspeak'));
      this.teamSpeakUpdates$ = this.withFallback(tsSocketUpdates, tsHttpUpdates).pipe(takeUntil(beforeUnload$));

      const gsSocketUpdates = this.socketSource('gameserver', x => (x as unknown as GameServerUpdateMessage).server);
      const gsHttpUpdates = this.triggerService.activeScreenTimer$.pipe(
        this.fetchHttp<GameServer[]>('gameserver'),
        map(value => value.filter(server => server !== null)),
        mergeMap(value => value)
      );
      this.serverUpdates$ = this.withFallback(gsSocketUpdates, gsHttpUpdates).pipe(takeUntil(beforeUnload$));
    } else {
      this.serverUpdates$ = EMPTY;
      this.teamSpeakUpdates$ = EMPTY;
    }
  }

  private fetchHttp<T>(topic: Topic): OperatorFunction<void, T> {
    return source => {
      return source
        .pipe(
          concatMap(() => {
            console.log('Starting HTTP Update');
            return this.httpClient.get<T>(`${environment.endpoints.api}/${topic}`, { responseType: 'json' }).pipe(
              catchError(() => of()),
              tap({
                error: x => console.error('Update via GET failed', x),
              })
            );
          })
        )
        .pipe(tap(x => isDevMode() && console.log('Http Updates:', x)));
    };
  }

  private withFallback<T>(primary: Observable<T>, fallback: Observable<T>) {
    const toggledFallback = this.socketService.onlineStatus$.pipe(switchMap(value => (value ? of() : fallback)));
    return merge(primary, toggledFallback).pipe(takeUntilDestroyed(this.destroyRef), share());
  }

  private socketSource<T>(topic: Topic, mapper: (x: Message<unknown>) => T): Observable<T> {
    return this.socketService.getUpdateStream(topic).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(value => mapper(value)),
      tap(x => isDevMode() && console.log('WS Update:', topic, x))
    );
  }
}
