<ng-template #skeleton>
  <div class="skeleton" @fadeOut>
    <div class="line">
      <ngx-skeleton-loader [appearance]="'line'" [theme]="channel"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="text(4)"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="channel" class="icon"></ngx-skeleton-loader>
    </div>
    <div class="level">
      <div class="line">
        <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="text(6)"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="channel" class="icon"></ngx-skeleton-loader>
      </div>
      <div class="line">
        <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="text(7)"></ngx-skeleton-loader>
      </div>
      <div class="level">
        <div class="line">
          <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="text(6)"></ngx-skeleton-loader>
        </div>
        <div class="line">
          <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="text(4)"></ngx-skeleton-loader>
        </div>
        <div class="line">
          <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="text(4)"></ngx-skeleton-loader>
        </div>
        <div class="line">
          <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="text(4)"></ngx-skeleton-loader>
        </div>
        <div class="line">
          <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="text(4)"></ngx-skeleton-loader>
        </div>
        <div class="line">
          <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="text(5)"></ngx-skeleton-loader>
        </div>
        <div class="line">
          <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="text(3)"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="line">
        <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="text(8)"></ngx-skeleton-loader>
      </div>
      <div class="level">
        <div class="line">
          <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="text(6)"></ngx-skeleton-loader>
        </div>
        <div class="level">
          <div class="line">
            <ngx-skeleton-loader [theme]="user"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="text(7)"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="channel" class="icon"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="channel" class="icon"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="channel" class="icon"></ngx-skeleton-loader>
          </div>
          <div class="line">
            <ngx-skeleton-loader [theme]="user"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="text(4)"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="channel" class="icon"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="channel" class="icon"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="line">
          <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="text(6)"></ngx-skeleton-loader>
        </div>
        <div class="line">
          <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="text(5)"></ngx-skeleton-loader>
        </div>
        <div class="line">
          <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="text(7)"></ngx-skeleton-loader>
        </div>
        <div class="line">
          <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="text(8)"></ngx-skeleton-loader>
        </div>
        <div class="line">
          <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="text(7)"></ngx-skeleton-loader>
        </div>
        <div class="line">
          <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="text(4)"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="line">
        <ngx-skeleton-loader [theme]="channel"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="text(4)"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="channel" class="icon"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>

<div
  class="card ts-card border-0"
  [ngClass]="{ online: dataAvailable(), offline: !dataAvailable() }"
  data-bs-theme="light">
  <picture>
    <img
      class="card-img-top img-fluid position-relative"
      priority=""
      fill=""
      draggable="false"
      style="height: 100px"
      ngSrc="/assets/img/games/teamspeak.webp"
      [sizes]="cardTopImageSizes"
      alt="Background image of the teamspeak card." />
  </picture>
  <div class="text-white cutout">
    <h2 class="card-title">TeamSpeak 3</h2>
  </div>
  <div class="card-body">
    <div class="card-text">
      <div class="holder row">
        @let result = this.state();
        @switch (result.status) {
          @case ('error') {
            @let error = result.error;
            @if (error !== null && $any(error).status === 503) {
              <span>Currently unavailable</span>
            } @else {
              <span>There has been a problem loading the data.{{ error.message }}</span>
            }
          }
          @case ('loaded') {
            @let server = this.state().data!;
            <div @fadeIn>
              <ul
                class="list-unstyled mb-0"
                style="
                  font-family: Arial, sans-serif;
                  font-size: 14px;
                  line-height: 16px;
                  image-rendering: pixelated !important;
                ">
                <li>
                  <div class="d-flex justify-content-between" style="height: 16px">
                    <div class="align-self-start">
                      <img
                        class="align-self-start"
                        style="vertical-align: top"
                        ngSrc="assets/img/ts/server.webp"
                        alt="Client"
                        width="16"
                        height="16"
                        disableOptimizedSrcset />
                      <span class="ms-1">{{ server.name }}</span>
                    </div>
                    <div class="align-self-end d-flex justify-content-start align-items-center" style="gap: 0.125rem">
                      @if (server.iconId) {
                        <img
                          ngSrc="{{ this.getIconUrl(server.iconId!) }}"
                          alt="Server Icon"
                          width="16"
                          height="16"
                          disableOptimizedSrcset />
                      }
                    </div>
                  </div>
                  <ul class="list-unstyled ms-3">
                    @for (channel of server.channels; track trackChannel($index, channel)) {
                      <li style="margin-top: 1px">
                        <ng-container *ngTemplateOutlet="channelTemplate; context: { channel: channel }"></ng-container>
                      </li>
                    }
                  </ul>

                  <ng-template #clientTemplate let-clientAny="client">
                    @if (asClient(clientAny); as client) {
                      <div class="d-flex justify-content-between" style="height: 16px">
                        <div class="align-self-start">
                          <img
                            class="align-self-start"
                            ngSrc="assets/img/ts/{{ this.clientIcon(client) }}"
                            alt="Client"
                            width="16"
                            height="16"
                            disableOptimizedSrcset />
                          <span class="ms-1">{{ client.name }}</span>
                        </div>
                        <div
                          class="align-self-end d-flex justify-content-start align-items-center"
                          style="gap: 0.125rem">
                          @if (client.channelGroup && client.channelGroup.iconId) {
                            <img
                              alt="Icon for the channel group {{ client.channelGroup.name }}"
                              title="{{ client.channelGroup.name }}"
                              ngSrc="{{ this.getIconUrl(client.channelGroup.iconId) }}"
                              width="16"
                              height="16"
                              disableOptimizedSrcset />
                          }
                          @for (sg of client.serverGroups; track trackGroup($index, sg)) {
                            @if (sg?.iconId) {
                              <img
                                alt="Icon for the server group {{ sg!.name }}"
                                title="{{ sg!.name }}"
                                ngSrc="{{ this.getIconUrl(sg!.iconId!) }}"
                                width="16"
                                height="16"
                                disableOptimizedSrcset />
                            }
                          }
                          @if (client.clientIcon) {
                            <img
                              alt="Client Icon"
                              title="Client Icon"
                              ngSrc="{{ this.getIconUrl(client.clientIcon) }}"
                              width="16"
                              height="16"
                              disableOptimizedSrcset />
                          }
                          @if (client.country || 'UNKNOWN'; as country) {
                            <img
                              alt="Flag of {{ country }}"
                              title="{{ country }}"
                              ngSrc="{{ this.getFlagIconUrl(country) }}"
                              width="16"
                              height="11"
                              disableOptimizedSrcset />
                          }
                        </div>
                      </div>
                    }
                  </ng-template>
                  <ng-template #channelTemplate let-channelAny="channel">
                    @if (asChannel(channelAny); as channel) {
                      <div class="d-flex justify-content-between" style="height: 16px">
                        @if (channel.spacer) {
                          @switch (channel.spacer.type) {
                            @case ('left') {
                              <span>{{ channel.spacer.text }}</span>
                            }
                            @case ('center') {
                              <span class="w-100 text-center">{{ channel.spacer.text }}</span>
                            }
                            @case ('right') {
                              <span class="w-100 text-end">{{ channel.spacer.text }}</span>
                            }
                            @case ('repeat') {
                              <span class="overflow-hidden">
                                @if (channel.spacer.text) {
                                  @for (_ of [].constructor(100 / (channel.spacer.text.length || 100)); track _) {
                                    {{ channel.spacer.text }}
                                  }
                                }
                              </span>
                            }
                          }
                        } @else {
                          <div class="align-self-start">
                            <img
                              class="align-self-start"
                              style="vertical-align: top"
                              ngSrc="assets/img/ts/{{ this.channelIcon(channel) }}"
                              alt="Channel {{ channel.isPasswordProtected ? ' (password protected)' : '' }}"
                              title="Channel {{ channel.isPasswordProtected ? ' (password protected)' : '' }}"
                              height="16"
                              width="16"
                              disableOptimizedSrcset />
                            <span class="ms-1">{{ channel.name }}</span>
                          </div>
                          <div
                            class="align-self-end d-flex justify-content-start align-items-center"
                            style="gap: 0.125rem">
                            @if (channel.isDefault) {
                              <img
                                title="Default channel"
                                ngSrc="assets/img/ts/channel-home.webp"
                                width="16"
                                height="16"
                                disableOptimizedSrcset
                                alt="Default channel" />
                            }
                            @if (channel.isPasswordProtected) {
                              <img
                                ngSrc="assets/img/ts/channel-password-right.webp"
                                width="16"
                                height="16"
                                disableOptimizedSrcset
                                alt="Password protected"
                                title="This channel is password protected." />
                            }
                            @if (channel.iconId) {
                              <img
                                ngSrc="{{ this.getIconUrl(channel.iconId) }}"
                                width="16"
                                height="16"
                                disableOptimizedSrcset
                                alt="Channel Icon" />
                            }
                          </div>
                        }
                      </div>
                      <ul class="list-unstyled ms-3">
                        @for (client of channel?.clients; track [client.id, client.name]) {
                          <li style="margin-top: 1px">
                            <ng-container
                              *ngTemplateOutlet="clientTemplate; context: { client: client }"></ng-container>
                          </li>
                        }
                        @for (channel of channel?.channels; track channel.id) {
                          <li style="margin-top: 1px">
                            <ng-container
                              *ngTemplateOutlet="channelTemplate; context: { channel: channel }"></ng-container>
                          </li>
                        }
                      </ul>
                    }
                  </ng-template>
                </li>
              </ul>
            </div>
          }
          @default {
            <ng-container *ngTemplateOutlet="skeleton"></ng-container>
          }
        }
        <ng-template #viewer></ng-template>
      </div>
    </div>
  </div>
</div>
