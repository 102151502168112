import { Injectable, inject, PLATFORM_ID } from '@angular/core';
import { Observable, fromEvent, merge, of, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class OnlineService {
  private readonly document = inject<Document>(DOCUMENT);
  private readonly platformId = inject(PLATFORM_ID);

  public readonly online$: Observable<Event>;
  public readonly offline$: Observable<Event>;
  public readonly status$: Observable<boolean>;

  private readonly navigator: Navigator;

  constructor() {
    const window: Window = this.document.defaultView!;

    if (isPlatformBrowser(this.platformId)) {
      this.online$ = fromEvent(window, 'online');
      this.offline$ = fromEvent(window, 'offline');
      this.status$ = merge(
        of(window.navigator.onLine),
        this.online$.pipe(map(() => true)),
        this.offline$.pipe(map(() => false))
      );
    } else {
      this.online$ = EMPTY;
      this.offline$ = EMPTY;
      this.status$ = of(true);
    }
    this.navigator = window.navigator;
  }

  public isOnline(): boolean {
    return this.navigator.onLine;
  }
}
