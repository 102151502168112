import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, input } from '@angular/core';
import 'iconify-icon';
import { addIcon, disableCache } from 'iconify-icon';
import chevronLeft from '@iconify-icons/mdi/chevron-left';
import map from '@iconify-icons/mdi/map';
import linkVariant from '@iconify-icons/mdi/link-variant';
import mastodon from '@iconify-icons/mdi/mastodon';
import github from '@iconify-icons/mdi/github';
import twitter from '@iconify-icons/mdi/twitter';

addIcon('mdi:chevron-left', chevronLeft);
addIcon('mdi:map', map);
addIcon('mdi:link-variant', linkVariant);
addIcon('mdi:mastodon', mastodon);
addIcon('mdi:github', github);
addIcon('mdi:twitter', twitter);
addIcon('custom:steam', {
  body: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>steam</title><path d="M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C7.4,22 3.55,18.92 2.36,14.73L6.19,16.31C6.45,17.6 7.6,18.58 8.97,18.58C10.53,18.58 11.8,17.31 11.8,15.75V15.62L15.2,13.19H15.28C17.36,13.19 19.05,11.5 19.05,9.42C19.05,7.34 17.36,5.65 15.28,5.65C13.2,5.65 11.5,7.34 11.5,9.42V9.47L9.13,12.93L8.97,12.92C8.38,12.92 7.83,13.1 7.38,13.41L2,11.2C2.43,6.05 6.73,2 12,2M8.28,17.17C9.08,17.5 10,17.13 10.33,16.33C10.66,15.53 10.28,14.62 9.5,14.29L8.22,13.76C8.71,13.58 9.26,13.57 9.78,13.79C10.31,14 10.72,14.41 10.93,14.94C11.15,15.46 11.15,16.04 10.93,16.56C10.5,17.64 9.23,18.16 8.15,17.71C7.65,17.5 7.27,17.12 7.06,16.67L8.28,17.17M17.8,9.42C17.8,10.81 16.67,11.94 15.28,11.94C13.9,11.94 12.77,10.81 12.77,9.42A2.5,2.5 0 0,1 15.28,6.91C16.67,6.91 17.8,8.04 17.8,9.42M13.4,9.42C13.4,10.46 14.24,11.31 15.29,11.31C16.33,11.31 17.17,10.46 17.17,9.42C17.17,8.38 16.33,7.53 15.29,7.53C14.24,7.53 13.4,8.38 13.4,9.42Z" /></svg>',
});
disableCache('all');

@Component({
  selector: 'app-icon',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  template: '<iconify-icon [style]="getStyle()" height="{{ height() }}" icon="{{ icon() }}"></iconify-icon>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class IconComponent {
  public readonly height = input<string>();
  public readonly icon = input.required<string>();
  public readonly inline = input(false, { transform: (v: boolean | '') => !(v === false) });

  protected getStyle() {
    return {
      display: this.inline() ? 'inline-block' : 'inline',
      'vertical-align': this.inline() ? 'bottom' : undefined,
    };
  }
}
