import { animate, group, style } from '@angular/animations';
import { ChangeDetectionStrategy, Component, effect, inject, isDevMode } from '@angular/core';
import { OnlineService } from '../../../online.service';
import { KeyValue, KeyValuePipe } from '@angular/common';
import { DataService } from '../data.service';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  asyncScheduler,
  buffer,
  connect,
  ignoreElements,
  merge,
  Observable,
  pipe,
  scan,
  share,
  throttle,
  timer,
  UnaryFunction,
} from 'rxjs';
import { GameServer } from '../../../model/gameserver';
import { TsViewerComponent } from '../ts-viewer/ts-viewer.component';
import { ServerComponent } from '../server/server.component';
import { NgxMasonryOptions } from '../../../masonry/ngx-masonry-options';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { NgxMasonryComponent } from '../../../masonry/ngx-masonry.component';
import { NgxMasonryDirective } from '../../../masonry/ngx-masonry.directive';

@Component({
  selector: 'app-serverlist',
  templateUrl: './serverlist.component.html',
  styleUrl: './serverlist.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TsViewerComponent, ServerComponent, NgxMasonryComponent, NgxMasonryDirective, KeyValuePipe],
})
export class ServerlistComponent {
  protected readonly dataService = inject(DataService);
  private readonly onlineService = inject(OnlineService);

  public masonryOptions: NgxMasonryOptions = {
    columnWidth: '.grid-sizer',
    horizontalOrder: false,
    percentPosition: true,
    initLayout: true,
    resize: true,
    animations: {
      show: [
        style({ opacity: 0 }),
        group([
          animate('.3s ease-out', style({ transform: 'translateX(0) translateY(0)' })),
          animate('.3s ease-in', style({ opacity: 1 })),
        ]), // for ts-viewer
      ],
      hide: [
        style({ opacity: '*' }),
        animate('.3s ease-in', style({ opacity: 0 })), // for ts-viewer
      ],
    },
  };

  readonly servers = toSignal(
    this.dataService.serverUpdates$.pipe(
      throttledBuffer(25, 2000),
      scan((acc, values) => {
        values.forEach(value => {
          acc.set(value.id, value);
        });
        return new Map(acc);
      }, new Map<string, GameServer>()),
      distinctUntilChanged(),
      startWith(new Map<string, GameServer>())
    ),
    { requireSync: true }
  );

  readonly e = isDevMode() && [
    effect(() => console.log('Serverlist: Servers', this.servers())),
    effect(() => console.log('Serverlist: Online', this.online())),
  ];

  readonly online = toSignal(this.onlineService.status$, { requireSync: true });

  sortServer<K extends KeyValue<string, GameServer>>(a: K, b: K) {
    const criteria = ['online', 'type', 'name', 'id'] as const;
    const orders = ['desc', 'asc', 'asc', 'asc'] as const;

    for (let i = 0; i < criteria.length; i++) {
      const key = criteria[i];
      const order = orders[i];
      const aValue = a.value[key];
      const bValue = b.value[key];

      if (aValue < bValue) return order === 'asc' ? -1 : 1;
      if (aValue > bValue) return order === 'asc' ? 1 : -1;
    }

    return 0; // They are equal in all criteria
  }
}

// from https://stackoverflow.com/a/66941053/3757624
function throttledBuffer<T>(
  initialTimeMs: number,
  throttleTimeMs: number
): UnaryFunction<Observable<T>, Observable<T[]>> {
  return pipe(
    connect(observable$ => {
      const throttleCalls$ = observable$.pipe(
        throttle(() => timer(initialTimeMs, throttleTimeMs, asyncScheduler), { leading: false, trailing: true })
      );

      const src$ = observable$.pipe(share());
      return merge(src$.pipe(ignoreElements()), src$.pipe(buffer(throttleCalls$)));
    })
  );
}
