<h2 class="d-none">List of Services</h2>
@if (online() === true) {
  <ngx-masonry id="server-list" class="row mb-n4 mx-mb-n4" [options]="masonryOptions" [ordered]="true">
    <div class="grid-sizer col-sm-6 col-md-4 col-lg-4 col-xl-3"></div>

    <app-ts-viewer ngxMasonryItem class="grid-item static col-sm-12 col-md-8 col-lg-4 col-xl-3 mb-4"></app-ts-viewer>

    @for (entry of servers() | keyvalue: sortServer; track entry.key) {
      <app-server
        ngxMasonryItem
        class="grid-item dynamic col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4"
        [server]="entry.value">
      </app-server>
    }
  </ngx-masonry>
} @else if (online() === false) {
  <div class="alert alert-warning fade show" role="alert">
    <h4 class="alert-heading">You are offline.</h4>
    You appear to be offline. Please connect to the internet for the latest status.
  </div>
}
