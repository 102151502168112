import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { ServerlistComponent } from './serverlist/serverlist.component';

const routes: Routes = [{ path: '', component: ServerlistComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class HomeModule {}
