/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Injector,
  input,
  isDevMode,
  OnChanges,
  OnDestroy,
  OnInit,
  output,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import { NgxMasonryOptions } from './ngx-masonry-options';
import { NgxMasonryDirective } from './ngx-masonry.directive';
import { Subject, tap, throttleTime } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { isPlatformBrowser } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ngx-masonry], ngx-masonry',
  template: '<ng-content></ng-content>',
  styles: `
    :host {
      display: block;
    }
  `,
})
export class NgxMasonryComponent implements OnInit, OnChanges, OnDestroy {
  private readonly element = inject(ElementRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly injector = inject(Injector);
  private readonly platformId = inject(PLATFORM_ID);

  // Inputs
  public readonly options = input<NgxMasonryOptions>({});
  public readonly ordered = input(false);

  // Outputs
  public readonly layoutComplete = output<any[]>();
  public readonly removeComplete = output<any[]>();
  public readonly itemsLoaded = output<number>();

  private masonryInstance?: any;
  private resizeObserver?: ResizeObserver;
  private readonly childResized = new Subject<void>();
  private pendingItems: NgxMasonryDirective[] = [];

  ngOnChanges(changes: SimpleChanges) {
    // only update layout if it's not the first change
    if (changes.updateLayout) {
      if (!changes.updateLayout.firstChange) {
        this.layout();
      }
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // Create masonry options object
      const options = this.options();

      // Set default itemSelector
      if (!options.itemSelector) {
        options.itemSelector = '[ngxMasonryItem], ngxMasonryItem';
      }

      this.childResized
        .pipe(
          tap(() => isDevMode() && console.log('Masonry: Child Resized')),
          takeUntilDestroyed(this.destroyRef),
          throttleTime(50, undefined, {
            leading: true,
            trailing: true,
          }),
          tap(() => isDevMode() && console.log('Masonry: Child Resized after Throttle'))
        )
        .subscribe(() => this.layout());

      fromPromise(import('masonry-layout'))
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(value => {
          if (value && this.masonryInstance === undefined)
            afterNextRender(
              () => {
                if (isDevMode()) {
                  console.log('Masonry: Initializing');
                }
                // Initialize Masonry
                this.masonryInstance = new value.default(this.element.nativeElement, {
                  ...this.options(),
                  transitionDuration: '0s', //animations are handled in css+angular
                });
                // Bind to events
                this.masonryInstance.layout!();

                this.masonryInstance.on!('layoutComplete', (items: any) => {
                  this.layoutComplete.emit(items);
                });
                this.masonryInstance.on!('removeComplete', (items: any) => {
                  this.removeComplete.emit(items);
                });

                if ('items' in this.masonryInstance) {
                  this.masonryInstance.items = [];
                } else {
                  throw Error('Could not reset masonry items.');
                }

                this.resizeObserver = new ResizeObserver(() => this.childResized.next());

                //there might be pending items that have been added in the meantime
                this.processPending();

                if (isDevMode()) {
                  console.log('Masonry: Initialized');
                }
              },
              { injector: this.injector }
            );
        });
    }
  }

  ngOnDestroy() {
    if (this.masonryInstance) {
      this.masonryInstance.destroy!();
      this.resizeObserver?.disconnect();
      this.resizeObserver = undefined;
      this.masonryInstance = undefined;
    }
  }

  public layout() {
    if (this.masonryInstance) {
      this.masonryInstance.layout!();
    }
  }

  public reloadItems() {
    if (this.masonryInstance) {
      this.masonryInstance!.reloadItems!();
    }
  }

  public addPendingItem(item: NgxMasonryDirective) {
    this.pendingItems.push(item);
  }

  public add(newItem: NgxMasonryDirective) {
    if (this.masonryInstance) {
      if (this.ordered()) {
        this.processPending();
      } else {
        this.itemLoaded(newItem);
      }
      this.reloadItems();
    }
  }

  private processPending() {
    for (const [index, item] of this.pendingItems.entries()) {
      if (item) {
        if (item.images && item.images.size === 0) {
          delete this.pendingItems[index];
          this.itemLoaded(item);
          if (index + 1 === this.pendingItems.length) {
            // All items are loaded
            this.itemsLoaded.emit(this.pendingItems.length);
            this.pendingItems = [];
          }
        } else {
          break;
        }
      }
    }
  }

  private itemLoaded(item: NgxMasonryDirective) {
    if (this.masonryInstance) {
      this.resizeObserver?.observe(item.element.nativeElement);
      // Tell Masonry that a child element has been added
      if (item.prepend()) {
        this.masonryInstance.prepended!(item.element.nativeElement);
      } else {
        this.masonryInstance.appended!(item.element.nativeElement);
      }

      // Check if first item
      if (this.masonryInstance.getItemElements!().length === 1) {
        this.masonryInstance.layout!();
      }
      item.playAnimation(true);
    }
  }

  public remove(element: HTMLElement) {
    if (this.masonryInstance) {
      // Tell Masonry that a child element has been removed
      this.masonryInstance.remove!([element]);
      this.resizeObserver?.unobserve(element);

      // Layout items
      this.layout();
    }
  }
}
