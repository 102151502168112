@let s = server();

<div [ngClass]="{ online: s.online, offline: !s.online }" class="card border-0" data-bs-theme="light">
  <picture style="height: 100px; width: 100%; image-rendering: pixelated">
    <img
      class="card-img-top img-fluid position-relative"
      priority=""
      fill=""
      draggable="false"
      ngSrc="/assets/img/games/{{ s.type }}.webp"
      [sizes]="topImageSizes"
      alt="Artistic image for the game {{ s.type }}." />
  </picture>
  <div class="text-white cutout">
    <h2 class="card-title">{{ s.type | titlecase }}</h2>
  </div>
  <nav>
    <ul>
      @if (s?.urls?.game) {
        <li>
          <a
            target="_blank"
            [href]="s!.urls!.game!"
            placement="end"
            ngbTooltip="Check out the games website"
            container="body"
            [openDelay]="1000">
            <app-icon height="1.25em" icon="{{ getGameUrlIcon(s!.urls!.game!)! }}"></app-icon>
            <div>
              <span>{{ getGameLinkText(s!.urls!.game!)! }}</span>
            </div>
          </a>
        </li>
      }
      @if (s?.urls?.map) {
        <li>
          <a
            target="_blank"
            href="{{ s!.urls!.map! }}"
            placement="end"
            ngbTooltip="Open a map of the server"
            container="body"
            [openDelay]="1000">
            <app-icon height="1.25em" icon="mdi:map"></app-icon>
            <div>
              <span>Map</span>
            </div>
          </a>
        </li>
      }
    </ul>
  </nav>

  <div class="card-body">
    <h3 class="fs-5 card-title">{{ s.name }}</h3>
    <p class="badges">
      <span
        class="badge rounded-pill"
        [ngClass]="{
          'bg-success': s.online,
          'bg-secondary': !s.online,
        }">
        {{ s.online ? 'Online' : 'Offline' }}
      </span>
      @if (s.online && s.players) {
        <span class="badge rounded-pill bg-primary ms-1"> {{ s.players.online }} / {{ s.players.max }} </span>
      }
    </p>

    @if (s.players.list?.length || 0 > 0) {
      <div class="card-text">
        Online Players:
        <ul>
          @for (player of s.players.list; track player) {
            <li>{{ player }}</li>
          }
        </ul>
      </div>
    }

    <!--    <p class="card-text" *ngIf="server.version">-->
    <!--      <small class="text-muted">Version: {{server.version}}</small>-->
    <!--    </p>-->
  </div>
  <div class="card-footer container">
    <div class="row justify-content-between g-0">
      <div class="col">
        <small class="text-muted">Version: {{ s.version || 'Unknown' }}</small>
      </div>
      <!--      <div class="col col-auto" *ngIf="server.urls?.connect">-->
      <!--        <small class="text-muted">-->
      <!--          <a href="{{server.urls.connect}}" ngbTooltip="Connect to {{server.urls.connect}}"-->
      <!--             [openDelay]="1000">-->
      <!--            <iconify-icon height="1.25em" icon="mdi:link-variant"></iconify-icon>-->
      <!--          </a>-->
      <!--        </small>-->
      <!--      </div>-->
      <!--    </div>-->
    </div>
  </div>
</div>
