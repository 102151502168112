import { ChangeDetectionStrategy, Component, input, inject } from '@angular/core';
import { ImageScaleBreakpointService } from '../../../image-scale-breakpoint.service';
import { GameServer } from '../../../model/gameserver';
import { NgClass, NgOptimizedImage, TitleCasePipe } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { IconComponent } from '../../icon/icon.component';

@Component({
  selector: 'app-server[server]',
  templateUrl: './server.component.html',
  styleUrl: './server.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgOptimizedImage, NgbTooltip, IconComponent, TitleCasePipe],
})
export class ServerComponent {
  private readonly imageScaleBreakPointService = inject(ImageScaleBreakpointService);

  public readonly server = input.required<GameServer>();
  protected readonly topImageSizes = this.imageScaleBreakPointService.generateBootstrapBreakpoints({
    xs: 'calc(100vw -24px)',
    sm: '10rem',
    md: '13.5rem',
    lg: '18.5rem',
    xl: '17rem',
    xxl: '20rem',
  });

  public getGameUrlIcon(url: string): string {
    const parsed = new URL(url);
    switch (parsed.host) {
      case 'store.steampowered.com':
        return 'custom:steam';
      default:
        return 'mdi:link-variant';
    }
  }

  getGameLinkText(url: string) {
    const parsed = new URL(url);
    switch (parsed.host) {
      case 'store.steampowered.com':
        return 'View on Steam';
      default:
        return parsed.host;
    }
  }
}
