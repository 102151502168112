import { Injectable, isDevMode, PLATFORM_ID, inject } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import getComputedStyle from '@popperjs/core/lib/dom-utils/getComputedStyle';

const BREAKPOINTS = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as const;
export type Breakpoint = (typeof BREAKPOINTS)[number];

@Injectable({
  providedIn: 'root',
})
export class ImageScaleBreakpointService {
  private readonly platformId = inject(PLATFORM_ID);
  private document = inject<Document>(DOCUMENT);

  private breakpointWidths: Record<Breakpoint, string>;

  constructor() {
    const document = this.document;

    if (isPlatformBrowser(this.platformId)) {
      const computedStyle = getComputedStyle(document.documentElement);
      this.breakpointWidths = Object.fromEntries(
        BREAKPOINTS.map(value => [value as Breakpoint, computedStyle.getPropertyValue(`--bs-breakpoint-${value}`)])
      ) as Record<Breakpoint, string>;
    } else {
      this.breakpointWidths = {
        xs: '0',
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
        xxl: '1400px',
      };
    }
  }

  public generateBootstrapBreakpoints(param: Partial<Record<Breakpoint, string>>) {
    return Object.entries(param)
      .reverse()
      .map(value => {
        const result = `${value[1]}`;
        const valueElement = value[0] as Breakpoint;
        const maxWidth = this.breakpointWidths[valueElement] || '0';
        if (maxWidth !== '0') {
          return `${isDevMode() ? `/*${valueElement}*/` : ''}(min-width: ${maxWidth}) ${result}`;
        }
        return result;
      })
      .join(', ');
  }
}
