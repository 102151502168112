import { ChangeDetectionStrategy, Component, computed, inject, TrackByFunction } from '@angular/core';
import { NgClass, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { ImageScaleBreakpointService } from '../../../image-scale-breakpoint.service';
import { environment } from '../../../environments/environment';
import { animate, style, transition, trigger } from '@angular/animations';
import findKey from 'lodash-es/findKey';
import { Channel, Group, VoiceClient } from '../../../model/teamspeak';
import { DataService } from '../data.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { NgxSkeletonLoaderConfigTheme, NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { catchError, map, startWith } from 'rxjs/operators';
import { Observable, of, pipe, UnaryFunction } from 'rxjs';

const fadeIn = trigger('fadeIn', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate('0.2s ease-out', style({ opacity: 1 })),
  ]),
]);

const fadeOut = trigger('fadeOut', [
  transition(':leave', [
    style({
      opacity: 1,
      position: 'absolute', //allows the new component to take its place
    }),
    animate('0.2s ease-in', style({ opacity: 0 })),
  ]),
]);

type State<T> =
  | { data: null; error: null; status: 'loading' }
  | { data: T; error: null; status: 'loaded' }
  | { data: null; error: Error; status: 'error' };

function toState<T>(): UnaryFunction<Observable<T>, Observable<State<T>>> {
  return pipe(
    map(value => {
      return { status: 'loaded' as const, data: value, error: null };
    }),
    catchError((err: Error) => {
      return of({ status: 'error' as const, data: null, error: err });
    }),
    startWith({ status: 'loading' as const, error: null, data: null })
  );
}

@Component({
  selector: 'app-ts-viewer',
  templateUrl: './ts-viewer.component.html',
  styleUrl: './ts-viewer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn, fadeOut],
  imports: [NgxSkeletonLoaderModule, NgClass, NgOptimizedImage, NgTemplateOutlet],
})
export class TsViewerComponent {
  private readonly breakpointService = inject(ImageScaleBreakpointService);
  private readonly dataService = inject(DataService);
  protected cardTopImageSizes = this.breakpointService.generateBootstrapBreakpoints({
    xs: 'calc(100vw -24px)',
    sm: '32.25rem',
    md: '28.75rem',
    lg: '18.5rem',
    xl: '17rem',
    xxl: '20rem',
  });

  protected readonly state = toSignal(this.dataService.teamSpeakUpdates$.pipe(toState()), {
    requireSync: true,
  });

  protected readonly dataAvailable = computed(() => this.state().status === 'loaded');

  trackChannel: TrackByFunction<Channel> = (index, item) => {
    return item['id'];
  };
  trackGroup: TrackByFunction<Group> = (index, item) => {
    return item['name'];
  };

  clientIcon(client: VoiceClient) {
    const conditionToImageMap = {
      'client-away': (c: VoiceClient) => !!c.away,
      'output-muted': (c: VoiceClient) => !!c.outputMuted,
      'input-muted': (c: VoiceClient) => !!c.inputMuted,
      'hardware-input-muted': (c: VoiceClient) => !!c.noInputHardware,
      'hardware-output-muted': (c: VoiceClient) => !!c.noOutputHardware,
      'client-channel-commander-talking': (c: VoiceClient) => !!c.channelCommander && !!c.talking,
      'client-channel-commander': (c: VoiceClient) => !!c.channelCommander,
      'client-talking': (c: VoiceClient) => !!c.talking,
      'client-normal': () => true,
    };

    const imageName = findKey(conditionToImageMap, condition => condition(client));
    return `${imageName || 'client-normal'}.webp`;
  }

  channelIcon(channel: Channel) {
    const conditionToImageMap = {
      'channel-full': (c: Channel) =>
        c.maxClients !== null && c.maxClients !== undefined && c.maxClients <= (c.clients?.length || 0),
      'channel-password': (c: Channel) => !!c.isPasswordProtected,
      'channel-normal': () => true,
    };

    const imageName = findKey(conditionToImageMap, condition => condition(channel));
    return `${imageName || 'channel-normal'}.webp`;
  }

  getIconUrl(iconId: number) {
    if ([100, 200, 300, 500, 600].includes(iconId)) {
      return `assets/img/ts/defaultGroups/${iconId}.webp`;
    }
    return `${environment.endpoints.api}/teamspeak/icon/${iconId}`;
  }

  asClient = (something: unknown) => something as VoiceClient;
  asChannel = (something: unknown) => something as Channel;

  private common: NgxSkeletonLoaderConfigTheme = {
    height: '14px',
    'margin-bottom': 0,
  };

  channel: NgxSkeletonLoaderConfigTheme = {
    ...this.common,
    width: '14px',
  };

  user: NgxSkeletonLoaderConfigTheme = {
    ...this.common,
    width: '14px',
    'border-radius': '9px',
  };

  text(number: number): NgxSkeletonLoaderConfigTheme {
    return {
      ...this.common,
      width: `${number}rem`,
    };
  }

  getFlagIconUrl(country: string | undefined) {
    return `assets/img/ts/flags/${country?.toLowerCase()}.webp`;
  }

  protected readonly Error = Error;
}
